
  @tailwind base;
  @tailwind components;
  @tailwind utilities;


.jaweb-chatbot .typing-indicator-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border:2px solid rgb(127, 40, 248);
  border-radius: 50%;
  margin: 0 2px;
  animation: typing-indicator 1s infinite;
}

@keyframes typing-indicator {
  0% {
    opacity: 0.5;
    transform: scale(0.7);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.5;
    transform: scale(0.7);
  }
}
